function convertBytes(bytes) {
  let value = bytes;
  let unit = " b";

  if (value > 100) {
    value = value / 1000;
    unit = " kb";
  }

  if (value > 100) {
    value = value / 1000;
    unit = " mb";
  }

  return Math.ceil(value) + unit;
}

function cropFileName(name) {
  const maxLength = 10
  const partialStringsLength = 4

  const ext = name.substring(name.lastIndexOf(".") + 1, name.length).toLowerCase();

  let filename = name.replace('.' + ext, '');

  if (filename.length > maxLength)
    filename = filename.substr(0, partialStringsLength) + '...' + filename.substr(filename.length - partialStringsLength, partialStringsLength)

  return filename + '.' + ext;
}

export default {
  convertBytes,
  cropFileName
}