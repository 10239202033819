<template>
  <div @click="changeThread" :class="{'bg-opacity-100 shadow-elevation-sm': active, 'cursor-pointer hover:bg-opacity-100 hover:shadow-elevation-sm' : !isLoadingFiles}"
       class="rounded-cards p-3 lg:pl-8 lg:pr-4 lg:py-4 grid grid-cols-12 gap-x-2 mb-2 bg-white bg-opacity-50 transition-all duration-300">
    <div class="col-span-2 flex justify-start items-center">
      <AvatarImage status-classes="w-2 h-2 absolute" :image="message.image_url" :isOnline="message.is_online"
                   width="w-10 lg:w-12"
                   height="h-10 lg:h-12"/>
    </div>
    <div class="col-span-8">
      <h4 class="body-m-700">{{ message.from }}</h4>
      <p class="body-xs-400">{{ latestMessage | truncate(35, "...") }}</p>
    </div>
    <div class="col-span-2 text-xs flex-shrink-0 flex flex-col items-end justify-self-end justify-between">
      <span class="text-fonts-tertiary body-xxs-400">{{ lastMessageTime }}</span>
      <span v-if="message.unread_count !== 0"
            class="rounded-full h-5 w-5 bg-secondary-main text-white flex justify-center items-center">{{
          message.unread_count
        }}</span>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import moment from "moment";
import isMobile from "is-mobile";
import AvatarImage from "@/components/shared/AvatarImage"

export default {
  name: 'MessageRow',
  components: {AvatarImage},
  props: {
    message: Object
  },

  data() {
    return {
      latestMessage: "",
    };
  },

  watch: {
    message(message) {
      const latestMessage = message.latest_message;
      this.latestMessage = 'text' in latestMessage ? latestMessage.text : latestMessage.files[0].name;
    }
  },

  methods: {
    ...mapMutations({
      setCurrentConversation: 'messages/setCurrentConversation'
    }),

    changeThread() {
      if(!this.isLoadingFiles){   
        if (isMobile()) {
          this.setCurrentConversation(this.message);
          this.$nextTick(()=>{
            this.$router.push({name: 'SingleThread', params: {message: this.message}});
        })
        } else {
          this.setCurrentConversation(this.message);
        }
      }
    }
  },
  
  computed: {
    ...mapState({
      currentConversation: state => state.messages.currentConversation,
    }),

    ...mapGetters({
      isLoadingFiles: 'messages/isLoadingFiles',
    }),

    lastMessageTime() {
      return moment(this.message.latest_message_time).format("MMM DD, hh:mm");
    },

    active() {
      return this.currentConversation && this.currentConversation.id == this.message.id
    }
  },

  mounted() {
    const latestMessage = this.message.latest_message;
    this.latestMessage = 'text' in latestMessage ? latestMessage.text : latestMessage.files[0].name;
  },
}
</script>
