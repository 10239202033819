<template>
  <Modal ref="modal" :closeButtonOutside="false" @close="$emit('stay')">
    <template v-slot:content>
      <div class="bg-white p-7 rounded-2xl" :class="classes">
        <div class="rounded-md w-7 flex items-center justify-center mb-2">
          <WarningIcon class="text-fonts-tertiary" />
        </div>

        <h2 class="body-m-400 font-bold mb-3">
          Your uploaded files have not been sent yet.
        </h2>

        <div class="mb-5">
          <p class="body-m-400">
            If you exit without sending, your files will not be sent.
          </p>
        </div>

        <div class="flex items-center justify-between">
          <ButtonIcon
            @onClick="$emit('leave')"
            text="Exit"
            fontWeight="font-normal"
            fontSize="text-sm"
            background="bg-transparent"
            color="text-secondary-main"
            border="border border-secondary-main"
            hover-background="hover:bg-secondary-dark hover:bg-opacity-10"
            hover-color="hover:text-secondary-main"
            xPadding="px-8"
          >
          </ButtonIcon>

          <ButtonIcon
            @onClick="$emit('stay')"
            :loading="loading"
            fontSize="text-sm"
            text="Stay"
            fontWeight="font-normal"
            xPadding="px-8"
          >
          </ButtonIcon>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/shared/Modal";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import WarningIcon from "../svg/WarningIcon.vue";
export default {
  components: { Modal, ButtonIcon, WarningIcon },
  mounted() {
    this.$refs.modal.toggle();
  },
};
</script>

<style>
</style>