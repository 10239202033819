<template>
  <div class="col-span-1 px-4 pt-10 overflow-hidden md:col-span-3 lg:px-5 messages-col">
    <h2 class="mx-2 mb-10 heading-l-500">Inbox</h2>
    <!--Header-->
    <TextInput v-model="searchQuery" class="flex-1 mx-2 mb-10" placeholder="Search messages...">
      <template slot="leftIcon">
        <SearchIcon class="absolute top-0 bottom-0 left-0 h-full ml-4 text-primary"/>
      </template>
      <template slot="rightIcon">
        <button @click="eraseSearch"
                class="absolute top-0 bottom-0 right-0 h-full mr-4 outline-none text-primary focus:outline-none">
          <XIcon/>
        </button>
      </template>
    </TextInput>
    <!--Messages rows-->
    <div v-if="!loading" id="messages-row" class="h-full px-2 pt-2 overflow-y-auto">
      <template v-if="conversations.length">
        <template v-if="filteredConversations.length">
          <MessageRow v-for="(conversation, index) in filteredConversations" :message="conversation" :key="index"/>  
        </template>
        <template v-else class="flex flex-col items-center justify-center px-20 text-center">
          <span class="px-6 mb-5 body-l-500">There are no contacts that match your search</span>
        </template>
      </template>
      <div v-else class="flex flex-col items-center justify-center px-20 text-center">
        <span class="px-6 mb-5 body-l-500">You don't have any contacts yet</span>
        <span class="mb-10 body-s-400 text-fonts-secondary">Once you make a hire for a job, you’ll be able to chat with them here</span>
        <HireOrLookJobsButton/>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import TextInput from "@/components/shared/inputs/TextInput"
import SearchIcon from "@/components/shared/svg/SearchIcon"
import XIcon from "@/components/shared/svg/XIcon"
import MessageRow from "@/components/inbox/MessageRow";
import HireOrLookJobsButton from "@/components/shared/buttons/HireOrLookJobsButton";

export default {
  name: 'MessagesCol',
  components: {MessageRow, TextInput, SearchIcon, XIcon, HireOrLookJobsButton},
  data() {
    return {
      searchQuery: '',
      loading: true,
    }
  },

  computed: {
    ...mapState({
      conversations: state => state.messages.conversations
    }),

    filteredConversations() {
      if (this.searchQuery !== '') {
        return this.conversations.filter((conv) => {
          const query = this.searchQuery.toLowerCase();
          const latestMessage = 'text' in conv.latest_message ? conv.latest_message.text : conv.latest_message.files[0].name;
          return latestMessage.toLowerCase().includes(query) || conv.from.toLowerCase().includes(query);
        })
      } else {
        return this.conversations;
      }
    }
  },

  async mounted() {
    await this.fetchConversations();
    this.loading = false;
  },

  methods: {
    ...mapActions({
      fetchConversations: 'messages/fetchConversations'
    }),

    eraseSearch() {
      this.searchQuery = '';
    }
  },
}
</script>

<style>
@media (min-width: 768px) {
  .messages-col {
    /* max-height: 65vh; */
    max-height: calc(100vh - 110px);
  }
}

#messages-row > *:last-child {
  margin-bottom: 13rem !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#messages-row::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#messages-row {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
