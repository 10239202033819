<template>
  <div class="relative">
    <div @click="openEmojiPicker" class="cursor-pointer">
      <SmileIcon />
    </div>
    <div v-if="showEmojiPicker" class="relative z-10">
      <div class="emoji-picker absolute z-10 right-0 bottom-10">
        <VEmojiPicker @select="selectEmoji" />
      </div>

      <div
        @click="closeEmojiPicker"
        class="backdrop fixed top-0 right-0 bottom-0 left-0 bg-transparent"
      ></div>
    </div>
  </div>
</template>
<script>
import { VEmojiPicker } from "v-emoji-picker";
import SmileIcon from "@/components/shared/svg/SmileIcon";
export default {
  name: "Message",
  components: { VEmojiPicker, SmileIcon },
  data() {
    return {
      showEmojiPicker: false,
    };
  },
  methods: {
    selectEmoji(emoji) {
      this.$emit("selectEmoji", emoji.data);
    },
    openEmojiPicker() {
      this.showEmojiPicker = true;
      document.addEventListener("keyup", this.handleEscape);
    },
    closeEmojiPicker() {
      this.showEmojiPicker = false;
      document.removeEventListener("keyup", this.handleEscape);
    },
    handleEscape(e) {
      if (e.keyCode === 27) this.closeEmojiPicker();
    },
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.handleEscape);
  },
};
</script>

<style scoped>
.emoji-picker {
  transform: translateX(15%);
}

@media (min-width: 540px) {
  .emoji-picker {
    transform: translateX(0);
  }
}
</style>

