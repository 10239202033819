<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M4.43585 16.565L16.46 10.9825C17.2917 10.5966 17.2917 9.40163 16.46 9.01579L4.43585 3.43496C3.57335 3.03496 2.66085 3.88329 2.98335 4.78579L4.84419 9.99746L2.98335 15.215C2.66085 16.1166 3.57335 16.965 4.43585 16.565Z"
          stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.84155 9.99992H17.0832" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "PapelAirplaneIcon"
}
</script>

<style scoped>

</style>
