<template>
  <div class="grid grid-cols-1">
    <ConversationCol :message="message" ref="ConversationCol" />
    <FilesPendingDialog
      v-if="showFilesPendingModal"
      @leave="leaveAndDeleteFiles"
      @stay="showFilesPendingModal = false"
    />
  </div>
</template>

<script>
import ConversationCol from "@/components/inbox/ConversationCol";
import FilesPendingDialog from "@/components/shared/modals/FilesPendingDialog";

export default {
  name: "SingleThread",
  components: { ConversationCol, FilesPendingDialog },
  props: { message: Object },
  data() {
    return {
      nextRoute: null,
      isOkToLeave: false,
      showFilesPendingModal: false,
    };
  },

  beforeRouteLeave(to, from, next) {
    if (this.isOkToLeave) {
      this.showDashly();
      next();
    }

    if (this.$refs.ConversationCol.isDirty()) {
      this.nextRoute = to;
      this.showFilesPendingModal = true;
    } else {
      this.showDashly();
      next();
    }
  },

  mounted() {
    this.hideDashly();
  },

  methods: {
    leaveAndDeleteFiles() {
      this.deleteUploadedMessages(
        this.$refs.ConversationCol.uploadedFiles.map((file) => {
          return file.fileUrl.split("/uploads/messages-files/")[1];
        })
      );
      this.isOkToLeave = true;
      this.$router.push(this.nextRoute);
    },

    hideDashly() {
      document
        .getElementById("carrotquest-messenger-collapsed-container")
        .classList.add("hidden");
    },

    showDashly() {
      document
        .getElementById("carrotquest-messenger-collapsed-container")
        .classList.remove("hidden");
    },
  },
};
</script>