<template>
  <div class="bg-primary-10 flex items-center justify-between p-2 rounded-md">
    <loading-icon v-if="status === 'loading'" class="h-1" />
    <span v-else-if="status === 'uploaded'"><CheckWithCircleIcon /></span>

    <div class="flex flex-col justify-center items-center">
      <span class="text-xs text-fonts-primary">
        {{ cropFileName(name) }}
      </span>
      <span class="text-xs text-primary-70"> ({{ convertBytes(size) }}) </span>
    </div>
    <span
      v-if="status === 'uploaded'"
      class="cursor-pointer"
      @click="$emit('deleteFile')"
      ><TrashIcon2
    /></span>
    <span v-else class="placeholder"></span>
  </div>
</template>

<script>
import TrashIcon2 from "@/components/shared/svg/TrashIcon2";
import CheckWithCircleIcon from "@/components/shared/svg/CheckWithCircleIcon";
import helperFunctions from "@/utilities/helper-functions";
export default {
  name: "SendableFile",
  components: { TrashIcon2, CheckWithCircleIcon },
  props: {
    name: String,
    size: Number,
    status: String,
  },
  methods: {
    convertBytes: helperFunctions.convertBytes,
    cropFileName: helperFunctions.cropFileName,
  },
};
</script>

<style scoped>
.placeholder {
  width: 17px;
}
</style>