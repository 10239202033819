<template>
  <div style="height: calc(100vh - 110px)"
       class="relative col-span-1 p-6 pt-6 overflow-hidden bg-white md:col-span-5 lg:col-span-4 lg:pl-10 lg:pr-20">
    <div v-if="currentConversation">
      <!--Header-->
      <div class="absolute top-0 left-0 right-0 z-10 w-full p-1 sm:p-6 lg:pl-10 lg:pr-20">
        <div class="flex items-center px-4 py-1 mb-5 bg-white rounded-xxl shadow-mainShadow sm:px-6 sm:py-3">
          <AvatarImage width="w-16" height="h-16" class="mr-5" :image="currentConversation.image_url" :isOnline="currentConversation.is_online"/>
          <div class="flex-1">
            <h4 class="body-l-700">{{currentConversation.from}}</h4>
            <span v-if="currentConversation.job_title" class="text-fonts-secondary body-s-400">{{ currentConversation.job_title }}</span>
          </div>
          <div v-if="!envIsProduction" class="flex items-center bg-pink-500">
            <ButtonIcon text="" background="bg-primary-10" rounded="rounded-full" x-padding="p-2"
                        y-padding="" font-size="text-xs lg:text-sm"
                        font-weight="font-normal">
              <template slot="leftIcon">
                <DotsVerticalIcon/>
              </template>
            </ButtonIcon>
          </div>
        </div>
      </div>
      <!--Content-->
      <div v-if="thread && thread.length > 0" id="messages" class="relative w-full h-screen overflow-y-auto pt-28">
        <Message v-for="message in thread" :message="message" :key="message.id" :me="message.user.id === user.id"/>
      </div>
      <!--Write a message-->
      <div class="absolute bottom-0 left-0 right-0 px-3 py-4 bg-white sm:px-10 sm:py-4">
        <div class="flex items-end">
          <AvatarImage width="w-8" height="h-8" class="hidden mr-5 lg:block" :image="user.image_url"/>
          <TextAreaInput @enterPressed="send" v-model="messageText" @input="setMessageText" placeholder="Write your message..." class="flex-1 mr-4" border="border" borderRadius="rounded-lg" x-padding="px-5" rows="1" :resizableInput="true" verticalAlign="middle" >
            <template v-slot:rightIcon1>
              <div class="relative">
                <ClipIcon class="clip-icon" />
                <input type="file" multiple @input="onInputFile" class="file-input" />
              </div>
            </template>
            <template v-slot:rightIcon2>
              <EmojiPicker @selectEmoji="selectEmoji" />
            </template>
          </TextAreaInput>
          <div>
            <ButtonIcon :disabled="!sendingIsEnabled" @onClick="send">
              <template slot="rightIcon">
                <PapelAirplaneIcon class="my-0.5"/>
              </template>
            </ButtonIcon>
          </div>
        </div>
        <!-- Files to be sent -->
        <div class="grid grid-cols-2 gap-2 mt-3 sm:grid-cols-3" v-if="files.length">
          <SendableFile
            v-for="file in sendableFiles"
            :key="file.file"
            :name="file.name"
            :size="file.size"
            :status="filesStatus[file.id]"
            @deleteFile="deleteFile(file)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import AvatarImage from "@/components/shared/AvatarImage";
import DotsVerticalIcon from "@/components/shared/svg/DotsVerticalIcon";
import Message from "@/components/inbox/Message";
import EmojiPicker from "@/components/inbox/EmojiPicker";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import PapelAirplaneIcon from "@/components/shared/svg/PapelAirplaneIcon";
import SendableFile from "@/components/inbox/SendableFile";
import ClipIcon from "@/components/shared/svg/ClipIcon";
import api from "@/api";
import TextAreaInput from "@/components/shared/inputs/TextAreaInput";

export default {
  name: 'ConversationCol',
  components: {
    PapelAirplaneIcon, 
    ButtonIcon, 
    Message, 
    DotsVerticalIcon, 
    AvatarImage,
    SendableFile,
    ClipIcon,
    EmojiPicker,
    TextAreaInput
  },
  props: {
    message: Object
  },
  data() {
    return {
      messageText: "",
      files: [],
      filesStatus: [],
      fileIdCounter: 0,
      maximumFilesAlowed: 10,
      maximumFileSize: 15000000,
      maximumFileSizeText: "15mb",
    };
  },

  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    this.restoreNonSent();
    if (this.$route.params.prewrittenMessage) {
      this.messageText = this.$route.params.prewrittenMessage;
    }
  }, 

  methods: {
    ...mapActions({
      fetchConversationMessages: 'messages/fetchConversationMessages',
      fetchConversations: 'messages/fetchConversations',
      sendMessage: 'messages/sendMessage'
    }),

    ...mapMutations({
      setNonSentMessages: 'messages/setNonSentMessages',
      setIsLoadingFiles: 'messages/setIsLoadingFiles',
    }),

    setMessageText(event) {
      this.setNonSent('messageText', event);
    },

    onInputFile({ target }) {
      if (
        target.files.length + this.sendableFiles.length >
        this.maximumFilesAlowed
      ) {
        alert(
          `You can upload a maximum of ${this.maximumFilesAlowed} files`
        );
        return;
      }

      target.files.forEach((file) => {
        if (file.size > this.maximumFileSize) {
          alert(
            `File ${file.name} exceeds the maximum limit of ${this.maximumFileSizeText} and won't be uploaded`
          );
        } else {
          file.id = this.fileIdCounter++;
          this.$set(this.files, file.id, file);
          this.uploadFile(file.id);
        }

        this.setNonSent('files', this.files);
        this.setNonSent('filesStatus', this.filesStatus);
      });
    },

    restoreNonSent(){
      this.files = [];
      this.filesStatus = [];
      this.messageText = "";
      const conversationNonSent = this.nonSentMessages.find(x => x.id === this.currentConversation.id);
      if(conversationNonSent) {
        if(conversationNonSent.messageText){
          this.messageText = conversationNonSent.messageText;
        }
        if(conversationNonSent.files){
          this.files = conversationNonSent.files;
          this.filesStatus = conversationNonSent.filesStatus;
        }
      }
    },

    setNonSent(type, toSave){
        const isConversationId = (element) => element.id === this.currentConversation.id
        var index = this.nonSentMessages.findIndex(isConversationId);
        if (index != -1) {
          this.nonSentMessages[index][type] = toSave;
        } else {
          this.nonSentMessages.push({ id: this.currentConversation.id, type: toSave });
        }
        this.setNonSentMessages(this.nonSentMessages);
    },

    async uploadFile(fileId) {
      const file = this.files[fileId];

      const uploadForm = new FormData();
      uploadForm.append("messageFile", file);

      this.$set(this.filesStatus, fileId, "loading");
      this.uploadingFiles();
      try {
        const { data } = await api.post("/uploads", uploadForm);
        const fileUrl = data.uploads[0];
        this.$set(this.filesStatus, fileId, "uploaded");
        this.$set(this.files[fileId], "fileUrl", fileUrl);
      } catch (error) {
        this.$set(this.filesStatus, fileId, "upload_error");
        console.log(error);
      }
      this.uploadingFiles();

      this.setNonSent('files', this.files);
      this.setNonSent('filesStatus', this.filesStatus);
    },


    uploadingFiles() {
      var loadingFiles = this.files.filter(
        (file) => this.filesStatus[file.id] === "loading"
      );
      if (loadingFiles.length > 0) {
        this.setIsLoadingFiles(true);
      } else {
        this.setIsLoadingFiles(false);
      }
    },

    async deleteFile(file) {
      const fileUrl = file.fileUrl.split("/uploads/messages-files/")[1];
      this.$set(this.filesStatus, file.id, "loading");
      this.uploadingFiles();
      try {
        await api.delete("/uploads/message-file/" + fileUrl);
        this.$set(this.filesStatus, file.id, "deleted");
      } catch (error) {
        this.$set(this.filesStatus, file.id, "delete_error");
      }
      this.uploadingFiles();
      this.setNonSent('files', this.files);
      this.setNonSent('filesStatus', this.filesStatus);
    },

    async send() {
      if (!this.sendingIsEnabled) return;

      const message = {};

      if (this.messageText !== "") message.text = this.messageText;

      if (this.uploadedFiles.length) {
        message.files = this.uploadedFiles.map((file) => {
          return {
            name: file.name,
            url: file.fileUrl,
            size: file.size,
          };
        });
      }

      let payload = {
        recipients: this.currentConversation.from_id,
        message: JSON.stringify(message),
      };
      await this.sendMessage(payload);
      this.setNonSent('messageText', '');
      this.setNonSent('files', []);
      this.setNonSent('filesStatus', []);

      this.messageText = "";
      this.files = [];
      this.filesStatus = [];
      this.fileIdCounter = 0;
      this.fetchConversationMessages(this.currentConversation.id);
      this.fetchConversations();
    },

    isDirty() {
      return this.uploadedFiles.length;
    },

    handleBeforeUnload(e) {
      if (this.isDirty()) {
        e = e || window.event;
        if (e) e.returnValue = "Sure?";
        return "Sure?";
      }
    },

    selectEmoji(emoji) {
      this.messageText += ` ${emoji}`
    },
  },
  watch: {
    async currentConversation() {
      await this.fetchConversationMessages(this.currentConversation.id);
      this.restoreNonSent();
    },
  },

  computed: {
    ...mapGetters({
      nonSentMessages: 'messages/nonSentMessages'
    }),

    ...mapState({
      currentConversation: state => state.messages.currentConversation,
      thread: state => state.messages.currentThread,
      user: state => state.auth.user,
    }),

    uploadedFiles() {
      return this.files.filter(
        (file) => this.filesStatus[file.id] === "uploaded"
      );
    },

    sendableFiles() {
      return this.files.filter(
        (file) =>
          this.filesStatus[file.id] !== "deleted" &&
          this.filesStatus[file.id] !== "upload_error" &&
          this.filesStatus[file.id] !== "delete_error"
      );
    },

    sendingIsEnabled() {
      return (
        (this.sendableFiles.length === 0 && this.messageText !== "") ||
        (this.sendableFiles.length > 0 &&
          this.sendableFiles.length === this.uploadedFiles.length)
      );
    },

    envIsProduction() {
      return process.env.NODE_ENV == 'production';
    }
  }
}
</script>

<style>
#messages > *:last-child {
  margin-bottom: 16rem !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#messages::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#messages {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.file-input {
  padding: 10px 10px;
  transform: translate(75%, -50%);
  width: 1px;
  height: 1px;
  margin-left: 5px;
  @apply absolute opacity-0 top-2/4 right-2/4 cursor-pointer
}

.clip-icon {
  margin-left: 5px;
}

</style>
