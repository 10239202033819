<template>
  <div class="downloadable-file">
    <span class="flex items-center gap-1">
      <span class="text-xs cursor-default font-bold text-auxiliary-blueLight">
        {{ file.name }}
      </span>
      <span
        class="text-xs whitespace-nowrap flex-1 cursor-default text-primary-70"
        >({{ convertBytes(file.size) }})</span
      >
    </span>

    <loading-icon v-if="loading" class="h-1 text-auxiliary-blueLight" />

    <span v-else @click="onDownloadFileClicked(file.url, file.name)">
      <DownloadIcon class="cursor-pointer" />
    </span>
  </div>
</template>

<script>
import DownloadIcon from "@/components/shared/svg/DownloadIcon";
import helperFunctions from "@/utilities/helper-functions";

export default {
  components: { DownloadIcon },
  props: {
    file: Object,
  },
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    onDownloadFileClicked(fileUrl, fileName) {
      this.loading = true;
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobURL;
          a.style = "display: none";

          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log("err", err);
        });
    },

    convertBytes: helperFunctions.convertBytes,
  },
};
</script>

<style scoped>
.downloadable-file {
  min-width: 220px;
  @apply flex gap-3 items-center justify-between w-full bg-white rounded-md px-2 sm:px-4 py-2 sm:py-3 mt-3;
}
</style>