<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2489 11.5687V14.5563C15.2489 14.616 15.2402 14.6711 15.2276 14.7223C15.1678 15.0158 14.9089 15.2362 14.6027 15.2362H2.39264C2.06526 15.2362 1.79367 14.9852 1.75912 14.6665C1.75049 14.628 1.75049 14.5941 1.75049 14.5516V11.564C1.75049 11.2114 2.03935 10.9265 2.38799 10.9265C2.56197 10.9265 2.72401 10.9989 2.83822 11.1138C2.9531 11.2287 3.02549 11.39 3.02549 11.564V13.9566H13.9779V11.564C13.9779 11.2114 14.2668 10.9265 14.6154 10.9265C14.7894 10.9265 14.9514 10.9989 15.0656 11.1138C15.1765 11.2327 15.2489 11.394 15.2489 11.5687L15.2489 11.5687Z"
      fill="#3144D3"
    />
    <path
      d="M12.1763 8.57635L9.04384 11.7088C9.03986 11.7174 9.03123 11.7214 9.02658 11.726C8.9117 11.8409 8.76294 11.9133 8.61419 11.9346C8.60158 11.9346 8.58896 11.9385 8.57568 11.9385C8.55044 11.9425 8.52455 11.9425 8.49931 11.9425L8.42693 11.9385C8.41431 11.9385 8.40169 11.9346 8.38841 11.9346C8.23568 11.9133 8.09091 11.8409 7.97603 11.726C7.97204 11.722 7.96341 11.7134 7.95876 11.7088L4.82634 8.57635C4.68158 8.43158 4.60986 8.24032 4.60986 8.04908C4.60986 7.85782 4.68225 7.66657 4.82634 7.5218C5.11521 7.23294 5.58736 7.23294 5.88021 7.5218L7.76279 9.40438L7.76478 2.50748C7.76478 2.09975 8.1008 1.76373 8.50853 1.76373C8.71239 1.76373 8.89966 1.84873 9.0358 1.98021C9.17193 2.11634 9.25228 2.29896 9.25228 2.50748V9.40506L11.1349 7.52248C11.4237 7.23362 11.8959 7.23362 12.1887 7.52248C12.465 7.816 12.465 8.28748 12.1761 8.57635H12.1763Z"
      fill="#3144D3"
    />
  </svg>
</template>

<script>
export default {
  name: "DownloadIcon",
};
</script>

<style scoped>
</style>
