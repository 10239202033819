<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
  >
    <path
      d="M9.5 0C14.7465 0 19 4.25353 19 9.5C19 14.7465 14.7465 19 9.5 19C4.25353 19 0 14.7465 0 9.5C0 4.25353 4.25353 0 9.5 0ZM9.5 1.07544C4.84728 1.07544 1.0754 4.84732 1.0754 9.50004C1.0754 14.1528 4.84728 17.9246 9.5 17.9246C14.1535 17.9246 17.9246 14.1528 17.9246 9.50004C17.9246 4.84732 14.1535 1.07544 9.5 1.07544Z"
      fill="#919EC1"
    />
    <path
      d="M12.8925 11.6079C13.1062 11.4023 13.4454 11.4089 13.651 11.6227C13.8558 11.8364 13.8499 12.1756 13.6361 12.3812C13.0135 12.9794 12.3492 13.4463 11.6529 13.7632C10.2457 14.4037 8.7539 14.4029 7.34676 13.7632C6.65058 13.4462 5.98632 12.9794 5.36354 12.3805C5.14979 12.1756 5.1431 11.8357 5.3487 11.6227C5.55428 11.4089 5.89346 11.4023 6.10721 11.6079C6.64529 12.1244 7.20863 12.523 7.79198 12.7887C8.90527 13.2956 10.0935 13.2956 11.2069 12.7887C11.7902 12.523 12.3543 12.1244 12.8916 11.6079H12.8925Z"
      fill="#919EC1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.91526 6.09406C6.60846 6.09406 7.17031 6.65589 7.17031 7.3491C7.17031 8.04156 6.60848 8.60415 5.91526 8.60415C5.22281 8.60415 4.66022 8.04232 4.66022 7.3491C4.66022 6.6559 5.22205 6.09406 5.91526 6.09406Z"
      fill="#919EC1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0847 6.09406C13.7779 6.09406 14.3398 6.65589 14.3398 7.3491C14.3398 8.04156 13.7779 8.60415 13.0847 8.60415C12.3923 8.60415 11.8297 8.04232 11.8297 7.3491C11.8297 6.6559 12.3915 6.09406 13.0847 6.09406Z"
      fill="#919EC1"
    />
  </svg>
</template>

<script>
export default {
  name: "SmileIcon",
};
</script>
