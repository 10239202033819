<template>
  <div style="height: calc(100vh - 110px)" class="xl:pl-40 bg-cream">
    <div class="flex flex-col md:grid md:grid-cols-8">
      <!--Left col-->
      <MessagesCol />
      <!--Right col-->
      <ConversationCol class="hidden md:block" ref="ConversationCol" />
    </div>

    <FilesPendingDialog
      v-if="showFilesPendingModal"
      @leave="leaveAndDeleteFiles"
      @stay="showFilesPendingModal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import MessagesCol from "@/components/inbox/MessagesCol";
import ConversationCol from "@/components/inbox/ConversationCol";
import FilesPendingDialog from "@/components/shared/modals/FilesPendingDialog";

export default {
  name: "Inbox",
  components: { ConversationCol, MessagesCol, FilesPendingDialog },
  data() {
    return {
      showFilesPendingModal: false,
      nextRoute: null,
      isOkToLeave: false,
    };
  },

  async mounted() {
    await this.fetchConversations();
    if (this.$route.params.threadId) {
      const conv = this.conversations.find(
        (conversation) => conversation.id == this.$route.params.threadId
      );
      this.setCurrentConversation(conv);
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.isOkToLeave) next();

    if (this.$refs.ConversationCol.isDirty()) {
      this.nextRoute = to;
      this.showFilesPendingModal = true;
    } else {
      next();
    }
  },

  methods: {
    ...mapActions({
      fetchConversations: "messages/fetchConversations",
      deleteUploadedMessages: "messages/deleteUploadedMessages",
    }),

    ...mapMutations({
      setCurrentConversation: "messages/setCurrentConversation",
    }),

    leaveAndDeleteFiles() {
      this.deleteUploadedMessages(
        this.$refs.ConversationCol.uploadedFiles.map((file) => {
          return file.fileUrl.split("/uploads/messages-files/")[1];
        })
      );
      this.isOkToLeave = true;
      this.$router.push(this.nextRoute);
    },
  },

  computed: {
    ...mapState({
      conversations: (state) => state.messages.conversations,
    }),
  },
};
</script>
