<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.31656 12.7617L14.8751 7.51607C15.8926 6.57678 17.3019 8.06435 16.2844 9.00364L10.0213 14.954C9.6294 15.3458 8.92555 15.3458 8.53369 14.8758L5.24579 11.5096C4.3065 10.5703 5.79407 9.16084 6.73336 10.1002L9.3166 12.7626L9.31656 12.7617Z"
      fill="#68D391"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0396 0C17.068 0 22 4.93284 22 11.0396C22 17.068 17.0672 22 11.0396 22C4.93284 22 0 17.0672 0 11.0396C0 4.93284 4.93284 0 11.0396 0ZM11.0396 19.9641C15.9724 19.9641 19.965 15.9716 19.965 11.0387C19.965 6.02778 15.9724 2.035 11.0396 2.035C6.02866 2.035 2.03588 6.02756 2.03588 11.0387C2.03588 15.9716 6.02844 19.9641 11.0396 19.9641Z"
      fill="#68D391"
    />
  </svg>
</template>

<script>
export default {
  name: "CheckIcon",
};
</script>

<style scoped></style>
