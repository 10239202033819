<template>
  <svg
    width="10"
    height="20"
    viewBox="0 0 10 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.74998 19.375C2.75544 19.375 1.80154 18.9797 1.09838 18.2765C0.395259 17.5734 -6.10352e-05 16.6195 -6.10352e-05 15.6249V5.62494C-6.10352e-05 3.839 0.953059 2.18814 2.49994 1.29454C4.04682 0.401577 5.95314 0.401577 7.49994 1.29454C9.04682 2.1883 9.99994 3.83914 9.99994 5.62494V17.4999C9.99994 17.8453 9.72026 18.1249 9.37494 18.1249C9.02962 18.1249 8.74994 17.8453 8.74994 17.4999V5.62494C8.74994 4.2851 8.0351 3.04754 6.87494 2.37734C5.71478 1.7078 4.28514 1.7078 3.12494 2.37734C1.96478 3.04766 1.24994 4.28516 1.24994 5.62494V15.6249C1.24994 17.0054 2.36948 18.1249 3.74994 18.1249C5.1304 18.1249 6.24994 17.0054 6.24994 15.6249V6.24994C6.24994 5.55932 5.69056 4.99994 4.99994 4.99994C4.30932 4.99994 3.74994 5.55932 3.74994 6.24994V14.3749C3.74994 14.7203 3.47026 14.9999 3.12494 14.9999C2.77962 14.9999 2.49994 14.7203 2.49994 14.3749V6.24994C2.49994 4.86948 3.61948 3.74994 4.99994 3.74994C6.3804 3.74994 7.49994 4.86948 7.49994 6.24994V15.6249C7.49994 16.6195 7.10462 17.5734 6.4015 18.2765C5.69838 18.9797 4.74446 19.375 3.7499 19.375H3.74998Z"
      fill="#919EC1"
    />
  </svg>
</template>

<script>
export default {
  name: "ClipIcon",
};
</script>

<style>
</style>