<template>
  <div class="flex w-full mb-8" :class="me ? 'pl-24 lg:pl-32 justify-end' : 'pr-24 lg:pr-32 justify-start'">
    <div :class="me ?  'order-1 ml-2 sm:ml-5' : 'mr-2 sm:mr-5'" class="flex-shrink-0 mt-4">
      <AvatarImage width="w-9" height="h-9" :image="message.user.image_url"/>
    </div>
    <div :class="me ?  'bg-auxiliary-blueLight text-white text-right' : 'bg-primary-10'" class="rounded-lg p-3 sm:p-5 relative">
      <div :class="me ? 'justify-end' : null" class="flex items-baseline space-x-3 mb-2">
        <h4 :class="me ?  'text-white' : null" class="font-semibold text-sm hidden md:block">{{ message.user.full_name }}</h4>
        <span class="text-xs">{{ createdAt }}</span>
        <button v-if="me && !envIsProduction" @click="showDelete = !showDelete" class="focus:outline-none outline-none bg-pink-500">
          <DotsHorizontalIcon/>
        </button>
      </div>
      <!-- Body -->
      <p class="message-text body-xs-400">{{ message.body.text }}</p>
      <div class="message-files" v-if="message.body.files">
        <DownloadableFile
          v-for="file in message.body.files"
          :key="file.name"
          :file="file"
        />
      </div>
      <!--Delete button-->
      <div v-if="showDelete"
           class="flex items-center space-x-3 bg-white p-2 rounded-md absolute right-0 top-0 mr-3 mt-10 cursor-pointer">
        <TrashIcon class="text-red-500"/>
        <span class="text-xs text-black">Delete Message</span>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import AvatarImage from "@/components/shared/AvatarImage"
import DotsHorizontalIcon from "@/components/shared/svg/DotsHorizontalIcon"
import TrashIcon from "@/components/shared/svg/TrashIcon";
import DownloadableFile from "@/components/inbox/DownloadableFile";

export default {
  name: 'Message',
  components: {TrashIcon, AvatarImage, DotsHorizontalIcon, DownloadableFile},
  props: {
    me: Boolean,
    message: Object
  },
  data() {
    return {
      showDelete: false
    }
  },
  computed: {
    createdAt() {
      return moment(this.message.created_at).format("MMM DD, hh:mm");
    },

    envIsProduction() {
      return process.env.NODE_ENV == 'production';
    }
  }
}
</script>

<style scoped>
.message-text {
  max-width: 230px;
  overflow-wrap: break-word;
}

@media (min-width:768px) {
  .message-text {
    max-width: 300px;
  }
}
</style>

